import { getBuyWasteDts, getReceiveWasteDts } from "helpers/backend_helper";
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const ReportWasteIn = () => {
  const [wasteInList, setWasteInList] = useState([])

  const getInitialBuyWaste = async () => {
    const responseBuyWaste = await getBuyWasteDts()
    const responseReceiveWaste = await getReceiveWasteDts()
    const concatArray = responseBuyWaste
      .map((item) => ({ ...item, type: 'รับซื้อ', qty: item.buyQty, member: item?.buyHd?.member }))
      .concat(responseReceiveWaste
        .map((item) => ({ ...item, type: 'รับบริจาค', qty: item.receiveQty, member: item?.receiveHd?.member })))
    const sortedArray = concatArray.sort((a, b) => {
      const dateA = new Date(a.created_at)
      const dateB = new Date(b.created_at)
      return dateB - dateA
    }
    )
    setWasteInList(sortedArray)
  }

  useEffect(() => {
    getInitialBuyWaste()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>รายงานรับเข้า | วิสาหกิจชุมชนแนวร่วมปฎิวัติขยะ</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs maintitle="เมนูหลัก" title="รายงาน" breadcrumbItem="รายงานรับเข้า" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">รายงานการรับเข้า</CardTitle>
                  <p className="card-title-desc">
                  </p>

                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>ประเภทเอกสาร</th>
                          <th>เลขที่เอกสาร</th>
                          <th>รหัสสินค้า</th>
                          <th>จำนวนรับเข้า(กก.)</th>
                          <th>รหัสผู้นำเข้า</th>
                          <th>วันที่นำเข้า</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wasteInList && wasteInList.map((item, idx) => {
                          return (
                            <tr>
                              <th scope="row">{idx + 1}</th>
                              <td>{item.type}</td>
                              <td>{item.id}</td>
                              <td>{item?.wasteMaterial?.id}</td>
                              <td>{item.qty}</td>
                              <td>{item?.member}</td>
                              <td>{item.created_at}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReportWasteIn
