import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import Select from "react-select"
//Lib for creating form
import { useForm } from "react-hook-form";
//Lib for calling api
import axios from 'axios'

import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCommunityLocations, postCommunityLocations, updateCommunityLocations } from "helpers/backend_helper";

const CommunityLocation = () => {

  //Existing items
  const [existingItem, setExisitingItem] = useState(null)
  const [optionExistingItems, setOptionExistingItems] = useState([])
  //Step1: Import useForm() from 'react-hook-form'
  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

  //Step3: Manage your logic in onSubmit fn
  async function onSubmit(data, type) {
    //Step4: Get waste materials data
    const payload = {
      communityDesc: data.communityDesc,
      communityProvince: data.communityProvince,
    }
    await postCommunityLocations(payload)
  }


  function handleSelectGroup(selectedGroup) {
    console.log(selectedGroup)
    setselectedGroup(selectedGroup)
  }

  function handleSelectExistingItem(selectedItem) {
    console.log(selectedItem)
    setExisitingItem(selectedItem)
  }

  async function getComunities() {
    const response = await getCommunityLocations()
    let formattedResponse = []
    if (response && response?.length > 0) {
      formattedResponse = response.map((commuData) =>
        ({ ...commuData, value: commuData['id'], label: commuData['communityDesc'] + ' (id:' + commuData['id'] + ')' })
      )
    }
    setOptionExistingItems(formattedResponse)
  }

  function fillData() {
    //move exitingItem to newItem
    setValue("id", existingItem.id)
    setValue("communityDesc", existingItem.communityDesc)
    setValue("communityProvince", existingItem.communityProvince)
  }

  async function saveChanges() {
    const id = getValues('id')
    const payload = {
      communityDesc: getValues('communityDesc'),
      communityProvince: getValues('communityProvince'),
    }
    await updateCommunityLocations(payload, id)
  }


  //Initial fetch data
  useEffect(() => {
    getComunities()
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>จัดการชุมชน | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs maintitle="การตั้งค่า" title="ตั้งค่า" breadcrumbItem="จัดการชุมชน" />
          <Row>
            <Col>
              {/* ---------------------------ค้นหาสินค้าปัจจุบัน--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    ค้นหารหัสชุมชน
                  </CardTitle>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      รหัสชุมชน
                    </label>
                    <div className="col-md-4">
                      <Select
                        value={existingItem}
                        onChange={(e) => {
                          handleSelectExistingItem(e)
                        }}
                        options={optionExistingItems}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Row>
                  <Button color="primary" type="button" onClick={() => fillData()}>
                    ดึงข้อมูล
                  </Button>
                </CardBody>
              </Card>


              {/* ---------------------------ข้อมูลชุมชน--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">รายละเอียดชุมชน</CardTitle>
                  <p className="card-title-desc">
                  </p>
                  <FormGroup onSubmit={handleSubmit(onSubmit)}>
                    <AvForm className="needs-validation">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          รหัสชุมชน
                        </label>
                        <div className="col-md-4">
                          {/* Step2: Register input with {...register("id")} */}
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            disabled
                            style={{ background: '#f5f5f5' }}
                            {...register("id")}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ชื่อชุมชน
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("communityDesc")}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ที่อยู่ชุมชน
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("communityProvince")}
                          />
                        </div>
                      </Row>

                      <Button color="primary" type="submit" style={{ marginRight: '10px' }}>
                        เพิ่มข้อมูล
                      </Button>
                      <Button color="primary" style={{ background: 'blue' }} onClick={() => saveChanges()}>
                        แก้ไขข้อมูล
                      </Button>
                    </AvForm>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default CommunityLocation
