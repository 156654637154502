import React from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const ReportInventories = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>รายงานสินค้าคงเหลือ | วิสาหกิจชุมชนแนวร่วมปฎิวัติขยะ</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs maintitle="เมนูหลัก" title="รายงาน" breadcrumbItem="รายงานสินค้าคงเหลือ" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">รายงานสินค้าคงเหลือ</CardTitle>
                  <p className="card-title-desc">
                  </p>

                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>ประเภทเอกสาร</th>
                          <th>เลขที่เอกสาร</th>
                          <th>รหัสสินค้า</th>
                          <th>จำนวนเบิก(กก.)</th>
                          <th>ผู้เบิก</th>
                          <th>สถานะเอกสาร</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>รับบริจาค</td>
                          <td>001</td>
                          <td>001</td>
                          <td>10</td>
                          <td>ชานนท์</td>
                          <td>ปกติ</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>รับซื้อ</td>
                          <td>002</td>
                          <td>001</td>
                          <td>4</td>
                          <td>ชวิศ</td>
                          <td>ปกติ</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>รับบริจาค</td>
                          <td>002</td>
                          <td>001</td>
                          <td>50</td>
                          <td>ชานนท์</td>
                          <td>ปกติ</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReportInventories
