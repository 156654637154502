import { getMembers } from "helpers/backend_helper";
import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const memberTypeMapping = {
  "ordinary": "สามัญ",
  "extraordinary": "วิสามัญ",
  "honorary": "กิตติมศักดิ์"
}

const ReportMembers = () => {
  const [membersList, setMembersList] = useState([])

  const getInitialBuyWaste = async () => {
    const response = await getMembers()
    console.log(response)
    setMembersList(response)
  }

  useEffect(() => {
    getInitialBuyWaste()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>รายงานราชื่อสมาชิก | วิสาหกิจชุมชนแนวร่วมปฎิวัติขยะ</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs maintitle="เมนูหลัก" title="รายงาน" breadcrumbItem="รายงานราชื่อสมาชิก" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">รายงานการรับเข้า</CardTitle>
                  <p className="card-title-desc">
                  </p>

                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>รหัส</th>
                          <th>คำนำหน้าชื่อ</th>
                          <th>ชื่อสมาชิก</th>
                          <th>วันเกิด</th>
                          <th>บัตรประจำตัวประชาชน</th>
                          <th>ประเภท</th>
                          <th>อาชีพหลัก</th>
                          <th>วันที่สมัคร</th>
                        </tr>
                      </thead>
                      <tbody>
                        {membersList && membersList.map((item, idx) => {
                          return (
                            <tr>
                              <th scope="row">{idx + 1}</th>
                              <td>{item.id}</td>
                              <td>{item.memberPrefix}</td>
                              <td>{item.memberName}</td>
                              <td>{item.birthdate}</td>
                              <td>{item.citizenId}</td>
                              <td>{memberTypeMapping?.[item.memberType]}</td>
                              <td>{item.mainJob}</td>
                              <td>{item?.created_at}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReportMembers
