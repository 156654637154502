import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getMembers, getWasteMaterials, postSaleWasteDts, postSaleWasteHds } from "helpers/backend_helper";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useInventoryContext } from "helpers/InventoryProvider";
import { getDateString } from "utils/getDate";

const SaleWaste = () => {
  const [user, setUser] = useState({})
  const [wasteMaterialList, setWasteMaterialList] = useState([{
    wasteMaterial: null,
    qty: null
  }])
  const [saleNote, setSaleNote] = useState("")
  const [items, setItems] = useState([{
    wasteMaterial: null,
    qty: null
  }])
  const { updateInventory, updateData, inventoriesByWasteId, communityId } = useInventoryContext()

  const getCurrentDate = () => {
    const today = new Date()
    const month = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
    const date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
    return today.getFullYear() + '-' + month + '-' + date
  }

  function handleAddRowNested1() {
    const newItem = {
      wasteMaterial: null,
      qty: null
    }
    setItems((prev) => [...prev, newItem])
  }

  const handleSelectWasteMaterial = (item, idx) => {
    const newItems = [...items]
    newItems[idx].wasteMaterial = item
    setItems(newItems)
  }

  const handleOnChangeQty = (e, idx) => {
    const newItems = items.map((item, sidx) => {
      if (idx !== sidx) return item
      return { ...item, qty: Number(e.target.value) }
    })
    setItems(newItems)
  }

  const handleOnChangePrice = (e, idx) => {
    const newItems = items.map((item, sidx) => {
      if (idx !== sidx) return item
      return { ...item, price: Number(e.target.value) }
    })
    setItems(newItems)
  }

  const deleteItem = (idx) => {
    const newItems = items.filter((item, sidx) => idx !== sidx)
    setItems(newItems)
  }

  const getInitialWasteMaterials = async () => {
    const response = await getWasteMaterials()
    if (response) {
      const optionResponse = response.filter((item) => item.status == 'active').map(item => {
        return { label: `${item.wasteName} (id: ${item.id})`, value: item.id, price: item.salePrice }
      })
      setWasteMaterialList(optionResponse)
    }
  }

  useEffect(() => {
    // getAllMembers()
    getInitialWasteMaterials()
  }, [])

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('authUser'))
    if (authUser) {
      setUser({
        id: authUser.user.id,
        username: authUser.user.username,
        email: authUser.user.email,
        displayName: authUser.user.displayName,
        communityId: `${authUser.user.community.communityDesc} (id: ${authUser.user.community.id})`,
        role: authUser.user.role.name,
      })
    }
  }, [])

  const handleSubmitData = async (event, errors, values) => {
    const time = values.date + 'T00:00:00.000Z'
    items.forEach((item) => {
      if (!item.wasteMaterial) {
        window.alert('Please select a material')
        return
      }
      if (!item.qty) {
        window.alert('Please enter a quantity')
        return
      }
    })
    try {
      const response = await postSaleWasteHds({
        status: 'normal',
        community: communityId,
        saleNote,
        users_permissions_user: user['id'],
        saleDate: time,
        saleTotalPrice: items.reduce((acc, curr) => acc + curr?.qty * curr?.wasteMaterial?.price, 0)
      })
      const saleHdId = response.id
      for (const item of items) {
        const wasteId = item.wasteMaterial.value
        await postSaleWasteDts({
          saleHd: saleHdId,
          wasteMaterial: wasteId,
          saleQty: item.qty,
          salePrice: item.wasteMaterial.price,
          saleLinePrice: item.wasteMaterial.price * item.qty,
          saleDate: time
        })
        await updateInventory(wasteId, -item.qty)
      }
      await updateData()
      window.alert('บันทึกเรียบร้อย')
      setItems(
        [{
          wasteMaterial: null,
          qty: 0
        }]
      )
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ขายออก | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <AvForm model={{ "date": getCurrentDate() }} onSubmit={handleSubmitData}>
            <Breadcrumbs maintitle="เมูนหลัก" title="เบิกออก" breadcrumbItem="ขายออก" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">หน้าขายออก</h4>
                    <Row>
                      <Col lg="2">
                        <div className="mb-3">
                          <AvField name="date" label="วันที่ขายออก" type="date" default />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">กรุณาระบุรหัสสินค้า</CardTitle>
                            <Form className="repeater">
                              {items.map((itemData, idx) => (
                                <React.Fragment key={idx}>
                                  <div data-repeater-list="group-a">
                                    <div data-repeater-item className="row">
                                      <div className="mb-4 col-lg-2">
                                        <Label>ชื่อสินค้า</Label>
                                        <Select
                                          value={itemData.wasteMaterial}
                                          onChange={(e) => {
                                            handleSelectWasteMaterial(e, idx)
                                          }}
                                          options={wasteMaterialList}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                      <div className="mb-4 col-lg-2">
                                        <label className="form-label" htmlFor="subject">จำนวน(กิโลกรัม)</label>
                                        <input
                                          type="number"
                                          id="subject"
                                          className="form-control"
                                          value={itemData.qty}
                                          onChange={(e) => handleOnChangeQty(e, idx)} />
                                      </div>
                                      <div className="mb-4 col-lg-2">
                                        <label className="form-label" htmlFor="subject">ราคาต่อหน่วย</label>
                                        <input
                                          type="number"
                                          id="price"
                                          className="form-control"
                                          value={itemData?.wasteMaterial?.price}
                                          onChange={(e) => handleOnChangePrice(e, idx)}
                                          disabled
                                          style={{ background: '#f5f5f5' }}
                                        />
                                      </div>
                                      <div className="mb-4 col-lg-2">
                                        <label className="form-label" htmlFor="subject">ยอดรวม</label>
                                        <input
                                          type="number"
                                          id="subject"
                                          className="form-control"
                                          value={itemData?.qty * itemData?.wasteMaterial?.price}
                                          disabled
                                          style={{ background: '#f5f5f5' }}
                                        />
                                      </div>
                                      <div className="mb-4 col-lg-2">
                                        <label className="form-label" htmlFor="subject">คงเหลือ</label>
                                        <input
                                          type="number"
                                          id="subject"
                                          className="form-control"
                                          value={inventoriesByWasteId?.[itemData?.wasteMaterial?.value]?.qty}
                                          disabled
                                          style={{ background: '#f5f5f5' }}
                                        />
                                      </div>
                                      <Col lg={2} className="align-self-center">
                                        <div className="d-grid">
                                          <input
                                            data-repeater-delete
                                            type="button"
                                            className="btn btn-primary"
                                            value="Delete"
                                            onClick={() => deleteItem(idx)}
                                          />
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))}
                              <div data-repeater-item className="row">
                                <div className="mb-8 col-lg-6">
                                </div>
                                <div className="mb-4 col-lg-2">
                                  <label className="form-label" htmlFor="subject">ยอดรวม</label>
                                  <input
                                    type="number"
                                    id="subject"
                                    className="form-control"
                                    value={items.reduce((acc, curr) => acc + curr?.qty * curr?.wasteMaterial?.price, 0)}
                                    disabled
                                    style={{ background: '#f5f5f5' }}
                                  />
                                </div>
                              </div>
                              <Button
                                onClick={() => {
                                  handleAddRowNested1()
                                }}
                                color="success"
                                className="btn btn-success mt-2 mt-lg-0"
                              >
                                Add
                              </Button>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="mb-3">
              <Label className="form-label" htmlFor="formmessage">หมายเหตุ :</Label>
              <Input
                type="textarea"
                id="formmessage"
                className="form-control"
                rows="3"
                onChange={(e) => setSaleNote(e.target.value)}
              />
            </div>
            <Button type="submit" color="primary">
              บันทึกรายการ
            </Button>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SaleWaste
