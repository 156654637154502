import { createContext, useContext, useEffect, useState } from "react";
import { getInventories, postInventory, updateInventoryById } from "./backend_helper";

const InventoryContext = createContext();

export function useInventoryContext() {
  return useContext(InventoryContext);
}

export function InventoryProvider({ children }) {
  const [inventories, setInventories] = useState();
  const [inventoriesByWasteId, setInventoriesByWasteId] = useState();
  const [communityId, setCommunityId] = useState();

  const updateInventory = async (wasteId, changedQty) => {
    if (inventoriesByWasteId[wasteId]) {
      return updateInventoryById(
        inventoriesByWasteId[wasteId]['id'],
        {
          qty: inventoriesByWasteId[wasteId]['qty'] + changedQty
        })
    } else {
      return postInventory({
        community: communityId,
        wasteMaterial: wasteId,
        qty: Number(changedQty)
      })
    }
  }

  const updateData = async () => {
    try {
      const authUser = localStorage.getItem("authUser")
      const community_id = JSON.parse(authUser).user.community.id
      const result = await getInventories(community_id);
      setInventories(result);
      setCommunityId(community_id)
      const emptyObject = {}
      result.forEach((item) => {
        emptyObject[item.wasteMaterial.id] = item
      })
      setInventoriesByWasteId(emptyObject)
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  return <InventoryContext.Provider value={{
    inventories: inventories,
    inventoriesByWasteId: inventoriesByWasteId,
    communityId: communityId,
    updateData: updateData,
    updateInventory: updateInventory
  }}>{children}</InventoryContext.Provider>;
}
