import axios from "axios"
import { post, del, get, put, patch } from "./api_helper"
import * as url from "./urls"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = (data) => post(url.POST_FAKE_REGISTER, data)

// Login Method
const postLogin = data => post(url.POST_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// WasteMaterials
const getWasteMaterials = () => get(url.GET_WASTE_MATERIALS)
const postWasteMaterials = (event) => post(url.GET_WASTE_MATERIALS, event)
const patchWasteMaterials = (event) => patch(url.GET_WASTE_MATERIALS, event)

// WasteTypes
const getWasteTypes = () => get(url.GET_WASTE_TYPES)

// Members
const getMembers = () => get(url.GET_ALL_MEMBERS)
const postMember = (event) => post(url.GET_ALL_MEMBERS, event)

// Members Groups
const getMemberGroups = () => get(url.GET_ALL_MEMBER_GROUPS)
const postMemberGroup = (event) => post(url.GET_ALL_MEMBER_GROUPS, event)

// WasteIn
// ReceiveWaste
const getReceiveWasteHds = () => get(url.RECEIVE_WASTE_HDS)
const postReceiveWasteHds = (event) => post(url.RECEIVE_WASTE_HDS, event)
const getReceiveWasteDts = (param) => get(param ? url.RECEIVE_WASTE_DTS + param : url.RECEIVE_WASTE_DTS)
const postReceiveWasteDts = (event) => post(url.RECEIVE_WASTE_DTS, event)
// BuyWaste
const getBuyWasteHds = () => get(url.BUY_WASTE_HDS)
const postBuyWasteHds = (event) => post(url.BUY_WASTE_HDS, event)
const getBuyWasteDts = (param) => get(param ? url.BUY_WASTE_DTS + param : url.BUY_WASTE_DTS)
const postBuyWasteDts = (event) => post(url.BUY_WASTE_DTS, event)

// WasteOut
// SaleWaste
const getSaleWasteHds = () => get(url.SALE_WASTE_HDS)
const postSaleWasteHds = (event) => post(url.SALE_WASTE_HDS, event)
const getSaleWasteDts = () => get(url.SALE_WASTE_DTS)
const postSaleWasteDts = (event) => post(url.SALE_WASTE_DTS, event)
// WithdrawWaste
const getWithdrawWasteHds = () => get(url.WITHDRAW_WASTE_HDS)
const postWithdrawWasteHds = (event) => post(url.WITHDRAW_WASTE_HDS, event)
const getWithdrawWasteDts = () => get(url.WITHDRAW_WASTE_DTS)
const postWithdrawWasteDts = (event) => post(url.WITHDRAW_WASTE_DTS, event)

// Community locations
const getCommunityLocations = () => get(url.COMMUNITY_LOCATIONS)
const postCommunityLocations = (event) => post(url.COMMUNITY_LOCATIONS, event)
const updateCommunityLocations = (event, id) => put(url.COMMUNITY_LOCATIONS + `/${id}`, event)

// Users
const getUsers = () => get(url.USERS)
const postUsers = (event) => post(url.USERS, event)
const updateUser = (event, id) => put(url.USERS + `/${id}`, event)

// Inventories
const getInventories = (communityId) => get(url.INVENTORY + `?community_eq=${communityId}`)
// const getInventories = (query) => get(url.INVENTORY + '?'+query)
const getInventoryById = (id) => get(url.INVENTORY + `?wasteMaterial=${id}`)
const updateInventoryById = (id, event) => put(url.INVENTORY + `/${id}`, event)
const postInventory = (event) => post(url.INVENTORY, event)


export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getWasteMaterials,
  getInventories,
  getInventoryById,
  updateInventoryById,
  postInventory,
  postWasteMaterials,
  patchWasteMaterials,
  getMembers,
  postMember,
  getReceiveWasteHds,
  postReceiveWasteHds,
  getReceiveWasteDts,
  postReceiveWasteDts,
  getBuyWasteHds,
  postBuyWasteHds,
  getBuyWasteDts,
  postBuyWasteDts,
  getSaleWasteHds,
  postSaleWasteHds,
  getSaleWasteDts,
  postSaleWasteDts,
  getWithdrawWasteHds,
  postWithdrawWasteHds,
  getWithdrawWasteDts,
  postWithdrawWasteDts,
  getCommunityLocations,
  postCommunityLocations,
  updateCommunityLocations,
  getUsers,
  postUsers,
  updateUser,
  getMemberGroups,
  postMemberGroup,
  getWasteTypes
}
