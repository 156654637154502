import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Waste Materials
import WasteMaterials from "../pages/WasteMaterials/WasteMaterials"

//Receive Waste
import ReceiveWaste from "../pages/WasteIn/ReceiveWaste/ReceiveWaste"

//Buy Waste
import BuyWaste from "../pages/WasteIn/BuyWaste/BuyWaste"

//WithdrawWaste
import WithdrawWaste from "../pages/WasteOut/WithdrawWaste/WithdrawWaste"

//SaleWaste
import SaleWaste from "../pages/WasteOut/SaleWaste/SaleWaste"
import ReportWasteIn from "pages/Report/ReportWasteIn/ReportWasteIn"
import ReportWasteOut from "pages/Report/ReportWasteOut/ReportWasteOut"
import ReportInventories from "pages/Report/ReportInventories/ReportInventories"
import ReportMembers from "pages/Report/ReportMembers/ReportMembers"
import MemberGroup from "pages/Setting/MemberGroup/MemberGroup"
import Users from "pages/Setting/Users/Users"
import MyProfile from "pages/MyProfile/MyProfile"
import Members from "pages/Members/Members"
import CommunityLocation from "pages/Setting/CommunityLocation/CommunityLocation"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  // Materials
  { path: "/waste-materials", component: WasteMaterials },

  // Receive Waste
  { path: "/receive-waste", component: ReceiveWaste },

  // Buy Waste
  { path: "/buy-waste", component: BuyWaste },

  // Withdraw Waste
  { path: "/withdraw-waste", component: WithdrawWaste },

  // Sale Waste
  { path: "/sale-waste", component: SaleWaste },

  // Users
  { path: "/Users", component: Users },

  // Report Buy Waste
  { path: "/report-waste-in", component: ReportWasteIn },

  // Report Receive Waste
  { path: "/report-waste-out", component: ReportWasteOut },

  // Report Sale Waste
  { path: "/report-inventories", component: ReportInventories },

  // Report Withdraw Waste
  { path: "/report-members", component: ReportMembers },

  // Member
  { path: "/members", component: Members },

  // Member Group
  { path: "/member-group", component: MemberGroup },

  // Community Location
  { path: "/community-location", component: CommunityLocation },

  // My Profile
  { path: "/my-profile", component: MyProfile },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { userRoutes, authRoutes }
