import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import Select from "react-select"

import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { updateUser } from "helpers/backend_helper";

const MyProfile = () => {
  const [user, setUser] = useState({
    id: "",
    username: "",
    email: "",
    displayName: "",
    communityId: "",
    password: "",
  })

  const handleUpdateUser = async () => {
    await updateUser(user, user.id)
  }

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('authUser'))
    if (authUser) {
      setUser({
        id: authUser.user.id,
        username: authUser.user.username,
        email: authUser.user.email,
        displayName: authUser.user.displayName,
        communityId: `${authUser.user.community.communityDesc} (id: ${authUser.user.community.id})`,
        role: authUser.user.role.name,
      })
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>จัดการชุมชน | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs maintitle="การตั้งค่า" title="ข้อมูลส่วนตัว" breadcrumbItem="ข้อมูลส่วนตัว" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">ข้อมูลส่วนตัว</CardTitle>
                  <p className="card-title-desc">
                  </p>
                  <AvForm className="needs-validation">
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        รหัสผู้ใช้งาน
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="text"
                          value={user.username}
                          onChange={(e) => { setUser({ ...user, username: e.target.value }) }}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        ชื่อผู้ใช้งาน
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="text"
                          value={user.displayName}
                          onChange={(e) => { setUser({ ...user, displayName: e.target.value }) }}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        อีเมลล์
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="email"
                          value={user.email}
                          onChange={(e) => { setUser({ ...user, email: e.target.value }) }}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        รหัสผ่าน
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="text"
                          value={user.password}
                          disabled
                          style={{ backgroundColor: "#f5f5f5" }}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        รหัสชุมชน
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control"
                          type="text"
                          value={user.communityId}
                          disabled
                          style={{ backgroundColor: "#f5f5f5" }}
                        />
                      </div>
                    </Row>
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => handleUpdateUser()}
                      disabled
                      actvie={false}
                    // style={{
                    //   background: "#f5f5f5",
                    //   cursor: "not-allowed"
                    // }}
                    >
                      แก้ไขข้อมูล
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyProfile
