import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import Select from "react-select"
//Lib for creating form
import { Controller, useForm } from "react-hook-form";
//Lib for calling api
import axios from 'axios'

import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getWasteMaterials, postWasteMaterials, patchWasteMaterials, getWasteTypes } from "helpers/backend_helper";

// Dropdown options
const optionStatus = [
  {
    options: [
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" }
    ]
  }
]

const WasteMaterials = () => {
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [existingItem, setExisitingItem] = useState(null)
  const [optionExistingItems, setOptionExistingItems] = useState([])
  const [optionWasteTypes, setOptionWasteTypes] = useState([])
  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues, control } = useForm();

  //Step3: Manage your logic in onSubmit fn
  async function onSubmit(data, type) {
    //Step4: Get waste materials data
    const payload = {
      wasteName: data.wasteName,
      status: selectedGroup.value,
      wasteType: data.wasteType.value,
      buyPrice: data.buyPrice,
      salePrice: data.salePrice,
      co2E: data.co2E,
      wasteRemark: data.wasteRemark,
    }
    try {
      await postWasteMaterials(payload)
      alert('บันทึกเรียบร้อย')
      setValue("wasteId", undefined)
      setValue("wasteName", undefined)
      setValue("buyPrice", undefined)
      setValue("co2E", undefined)
      setValue("salePrice", undefined)
      setValue("wasteRemark", undefined)
      setValue("wasteTypeId", undefined)
      setSelectedGroup(null)
    } catch (err) {
      alert('Unable to add new waste material')
    }
  }

  function handleSelectGroup(selectedGroup) {
    console.log(selectedGroup)
    setSelectedGroup(selectedGroup)
  }

  function handleSelectExistingItem(selectedItem) {
    console.log(selectedItem)
    setExisitingItem(selectedItem)
  }

  async function getInitialWasteMaterials() {
    const response = await getWasteMaterials()
    if (!response) return
    const formattedResponse = response.map((wasteMatData) => ({ ...wasteMatData, value: wasteMatData['id'], label: wasteMatData['wasteName'] + '- (id:' + wasteMatData['id'] + ')' }))
    setOptionExistingItems(formattedResponse)
  }

  function fillData() {
    //move exitingItem to newItem
    setValue("wasteId", existingItem.wasteId)
    setValue("wasteName", existingItem.wasteName)
    setValue("buyPrice", existingItem.buyPrice)
    setValue("co2E", existingItem.co2E)
    setValue("salePrice", existingItem.salePrice)
    setValue("wasteRemark", existingItem.wasteRemark)
    setValue("wasteTypeId", existingItem.wasteTypeId)
    setSelectedGroup({ label: existingItem.status, value: existingItem.status })
  }

  async function saveChanges() {
    const id = getValues('wasteId')
    const payload = {
      wasteName: getValues('wasteName'),
      status: selectedGroup.value,
      wasteType: getValues('wasteType')['value'],
      buyPrice: getValues('buyPrice'),
      salePrice: getValues('salePrice'),
      co2E: getValues('co2E'),
      wasteRemark: getValues('wasteRemark'),
    }
    try {
      await patchWasteMaterials(payload)
      setValue("wasteId", undefined)
      setValue("wasteName", undefined)
      setValue("buyPrice", undefined)
      setValue("co2E", undefined)
      setValue("salePrice", undefined)
      setValue("wasteRemark", undefined)
      setValue("wasteTypeId", undefined)
      setSelectedGroup({ label: existingItem.status, value: existingItem.status })
    } catch (err) {
      alert('Unable to update waste material')
    }
  }

  const getAllWasteTypes = async () => {
    const response = await getWasteTypes()
    setOptionWasteTypes(response.map((item) => ({ value: item['id'], label: `${item['wasteTypeDesc']} (id: ${item['id']})` })))
  }

  //Initial fetch data
  useEffect(() => {
    getInitialWasteMaterials()
    getAllWasteTypes()
  }, [])

  // Listen to item selection
  useEffect(() => {
    if (existingItem) {
      fillData(existingItem)
    }
  }, [existingItem])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ข้อมูลสินค้า | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs maintitle="เมนูหลัก" title="ข้อมูลสินค้า" breadcrumbItem="ข้อมูลสินค้า" />
          <Row>
            <Col>
              {/* ---------------------------ค้นหาสินค้าปัจจุบัน--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    ค้นหาสินค้าปัจจุบัน
                  </CardTitle>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      ชื่อสินค้า
                    </label>
                    <div className="col-md-4">
                      <Select
                        value={existingItem}
                        onChange={(e) => {
                          handleSelectExistingItem(e)
                        }}
                        options={optionExistingItems}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Row>
                  <Button color="primary" type="button" onClick={() => fillData()}>
                    ดึงข้อมูล
                  </Button>
                </CardBody>
              </Card>

              {/* ---------------------------ข้อมูลสินค้า--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">ข้อมูลสินค้า</CardTitle>
                  <p className="card-title-desc">
                  </p>
                  <FormGroup onSubmit={handleSubmit(onSubmit)}>
                    <AvForm className="needs-validation">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          รหัสสินค้า
                        </label>
                        <div className="col-md-4">
                          {/* Step2: Register input with {...register("id")} */}
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            disabled
                            style={{ backgroundColor: '#f2f2f2' }}
                            {...register("wasteId")}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          รายละเอียดสินค้า
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("wasteName", { required: "Waste name is required" })}
                          />
                          {errors?.wasteName && errors.wasteName.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ประเภทสินค้า
                        </label>
                        <div className="col-md-4">
                          <Controller
                            name="wasteType"
                            control={control}
                            render={({ field }) => <Select  {...field} options={optionWasteTypes} />}
                          />
                          {/* <input
                            className="form-control"
                            type="number"
                            defaultValue=""
                            {...register("wasteTypeId", { required: "Waste Type is required" })}
                          /> */}
                          {/* {errors?.wasteTypeId && errors.wasteTypeId.message} */}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ราคาซื้อ
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("buyPrice", { required: "Buy Price is required" })}
                          />
                          {errors?.wasteTypeId && errors.wasteTypeId.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ราคาขาย
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("salePrice", { required: "Sale Price is required" })}
                          />
                          {errors?.salePrice && errors.salePrice.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ปริมาณ CO2E
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("co2E", { required: "CO2 Equivalent is required" })}
                          />
                          {errors?.co2E && errors.co2E.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Remark
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("wasteRemark")}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          สถานะสินค้า
                        </label>
                        <div className="col-md-4">
                          <Select
                            value={selectedGroup}
                            onChange={(e) => {
                              handleSelectGroup(e)
                            }}
                            options={optionStatus}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Row>
                      <Button color="primary" type="submit" style={{ marginRight: '10px' }}>
                        เพิ่มข้อมูล
                      </Button>
                      <Button color="primary" style={{ background: 'blue' }} onClick={() => saveChanges()}>
                        แก้ไขข้อมูล
                      </Button>
                    </AvForm>
                  </FormGroup>
                </CardBody>

              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default WasteMaterials
