import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import Select from "react-select"
//Lib for creating form
import { Controller, useForm } from "react-hook-form";
//Lib for calling api
import axios from 'axios'

import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCommunityLocations, getUsers, postUsers } from "helpers/backend_helper";

const Users = () => {
  //Selected dropdown value
  const [communityList, setCommunityList] = useState([])
  //Existing items
  const [existingItem, setExisitingItem] = useState(null)
  const [optionExistingItems, setOptionExistingItems] = useState([])
  //Step1: Import useForm() from 'react-hook-form'
  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues, control } = useForm();

  //Step3: Manage your logic in onSubmit fn
  async function onSubmit(data, type) {
    //Step4: Get waste materials data
    const payload = {
      username: data.username,
      password: data.password,
      displayName: data.displayName,
      email: data.email,
      community: 1,
    }
    await postUsers(payload)
  }

  function handleSelectExistingItem(selectedItem) {
    setExisitingItem(selectedItem)
  }

  async function getUserList() {
    const response = await getUsers()
    let formattedResponse = []
    if (response) {
      formattedResponse = response.map((userData) => ({ ...userData, value: userData['id'], label: userData['username'] + ' (id: ' + userData['id'] + ')' }))
    }
    setOptionExistingItems(formattedResponse)
  }

  function fillData() {
    setValue("id", existingItem.id)
    setValue("username", existingItem.username)
    setValue("password", existingItem.password)
    setValue("displayName", existingItem.displayName)
    setValue("email", existingItem.email)
  }

  async function saveChanges() {
    const id = getValues('id')
    const payload = {
      user_Name: getValues('username'),
      password: getValues('password'),
      displayName: getValues('displayName'),
      email: getValues('email'),
      rold: 2,
      community: 1,
    }
    await axios.put(`http://localhost:8080/Users/${id}`, payload)
  }

  const getAllCommunityLocations = async () => {
    const response = await getCommunityLocations()
    if (response) {
      const commuResponse = response.map((item) => ({ ...item, value: item['id'], label: `${item['communityDesc']} (id : ${item['id']})` }))
      setCommunityList(commuResponse)
    }
  }

  useEffect(() => {
    getAllCommunityLocations()
  }, [])

  //Initial fetch data
  useEffect(() => {
    getUserList()
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>จัดการชุมชน | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs maintitle="การตั้งค่า" title="ตั้งค่า" breadcrumbItem="จัดการผู้ใช้งาน" />

          <Row>
            <Col>
              {/* ---------------------------ค้นหาสินค้าปัจจุบัน--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    ค้นหาผู้ใช้ปัจจุบัน
                  </CardTitle>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      ชื่อผู้ใช้
                    </label>

                    <div className="col-md-4">
                      <Select
                        value={existingItem}
                        onChange={(e) => {
                          handleSelectExistingItem(e)
                        }}
                        options={optionExistingItems}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Row>
                  <Button color="primary" type="button" onClick={() => fillData()}>
                    ดึงข้อมูล
                  </Button>
                </CardBody>
              </Card>

              {/* ---------------------------ข้อมูลผู้ใช้--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">รายละเอียดผู้ใช้งาน</CardTitle>
                  <p className="card-title-desc">
                  </p>
                  <FormGroup onSubmit={handleSubmit(onSubmit)}>
                    <AvForm className="needs-validation">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ชื่อผู้ใช้งาน (Display name)
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            required
                            {...register("displayName", { required: "This field is required" })}
                          />
                          {errors?.displayName && errors.displayName.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          รหัสผู้ใช้งาน (Username)
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="username"
                            defaultValue=""
                            {...register("username", { required: "This field is required" })}
                          />
                          {errors?.username && errors.username.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          รหัสผ่าน (Password)
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="password"
                            defaultValue=""
                            {...register("password", { required: "This field is required" })}
                          />
                          {errors?.password && errors.password.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          อีเมลล์
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="email"
                            defaultValue=""
                            {...register("email", { required: "This field is required" })}
                          />
                          {errors?.email && errors.email.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ชุมชน
                        </label>
                        <div className="col-md-4">
                          <Controller
                            name="community"
                            control={control}
                            render={({ field }) => <Select  {...field} options={communityList} />}
                          />
                          {errors?.community && errors.community.message}
                        </div>
                      </Row>

                      <Button color="primary" type="submit" style={{ marginRight: '10px' }}>
                        เพิ่มข้อมูล
                      </Button>
                      <Button color="primary" style={{ background: 'blue' }} onClick={() => saveChanges()}>
                        แก้ไขข้อมูล
                      </Button>
                    </AvForm>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
