import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import Select from "react-select"
import { Controller, useForm } from "react-hook-form";

import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
  CardTitle,
  Container,
  Label,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCommunityLocations, getMemberGroups, postCommunityLocations, postMemberGroup, updateCommunityLocations } from "helpers/backend_helper";

const MemberGroup = () => {

  //Existing items
  const [communityList, setCommunityList] = useState([])
  const [existingItem, setExisitingItem] = useState(null)
  const [optionExistingItems, setOptionExistingItems] = useState([])
  //Step1: Import useForm() from 'react-hook-form'
  const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();

  //Step3: Manage your logic in onSubmit fn
  async function onSubmit(data, type) {
    if (!data.memberGroupName) {
      alert("โปรดระบุชื่อกลุ่มสมาชิก")
      return
    }
    //Step4: Get waste materials data
    try {
      const payload = {
        memberGroupName: data.memberGroupName,
        community: data.community.id,
      }
      await postMemberGroup(payload)
      alert("บันทึกเรียบร้อย")
      setValue("id", undefined)
      setValue("memberGroupName", undefined)
    } catch (err) {
      console.log(err)
    }
  }

  function handleSelectExistingItem(selectedItem) {
    setExisitingItem(selectedItem)
  }

  async function getAllMemberGroups() {
    const response = await getMemberGroups()
    let formattedResponse = []
    if (response && response?.length > 0) {
      formattedResponse = response.map((commuData) =>
        ({ ...commuData, value: commuData['id'], label: commuData['memberGroupName'] + ' (id:' + commuData['id'] + ')' })
      )
    }
    setOptionExistingItems(formattedResponse)
  }

  const getAllCommunityLocations = async () => {
    const response = await getCommunityLocations()
    if (response) {
      const commuResponse = response.map((item) => ({ ...item, value: item['id'], label: `${item['communityDesc']} (id : ${item['id']})` }))
      setCommunityList(commuResponse)
    }
  }

  function fillData() {
    if (existingItem["id"]) {
      setValue("id", existingItem.id)
      setValue("memberGroupName", existingItem.memberGroupName)
      setValue("community", { value: existingItem.community.id, label: existingItem.community.communityDesc })
    } else {
      alert('Please select a member group')
    }
  }

  async function saveChanges() {
    const id = getValues('id')
    const payload = {
      memberGroupName: getValues('memberGroupName'),
      community: getValues('community').value,
    }
    await updateCommunityLocations(payload, id)
  }


  //Initial fetch data
  useEffect(() => {
    getAllMemberGroups()
    getAllCommunityLocations()
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>จัดการชุมชน | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs maintitle="การตั้งค่า" title="ตั้งค่า" breadcrumbItem="จัดการชุมชน" />
          <Row>
            <Col>
              {/* ---------------------------ค้นหาสินค้าปัจจุบัน--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    ค้นหากลุ่มสมาชิก
                  </CardTitle>
                  <Row className="mb-3">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-2 col-form-label"
                    >
                      กลุ่มสมาชิก
                    </label>
                    <div className="col-md-4">
                      <Select
                        value={existingItem}
                        onChange={(e) => {
                          handleSelectExistingItem(e)
                        }}
                        options={optionExistingItems}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Row>
                  <Button color="primary" type="button" onClick={() => fillData()}>
                    ดึงข้อมูล
                  </Button>
                </CardBody>
              </Card>


              {/* ---------------------------ข้อมูลชุมชน--------------------------- */}
              <Card>
                <CardBody>
                  <CardTitle className="h4">รายละเอียดชุมชน</CardTitle>
                  <p className="card-title-desc">
                  </p>
                  <FormGroup onSubmit={handleSubmit(onSubmit)}>
                    <AvForm className="needs-validation">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          รหัสชุมชน
                        </label>
                        <div className="col-md-4">
                          {/* Step2: Register input with {...register("id")} */}
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            disabled
                            style={{ background: '#f5f5f5' }}
                            {...register("id")}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ชื่อกลุ่มสมาชิก
                        </label>
                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("memberGroupName", { required: "โปรดระบุชื่อกลุ่มสมาชิก" }
                            )}
                          />
                          {errors?.memberGroupName && errors.memberGroupName.message}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          ที่อยู่ชุมชน
                        </label>
                        <div className="col-md-4">
                          <Controller
                            name="community"
                            control={control}
                            render={({ field }) => <Select {...field} options={communityList} />}
                          />
                          {errors?.community && errors.community.message}
                          {/* <input
                            className="form-control"
                            type="text"
                            defaultValue=""
                            {...register("communityProvince")}
                          /> */}
                        </div>
                      </Row>

                      <Button color="primary" type="submit" style={{ marginRight: '10px' }}>
                        เพิ่มข้อมูล
                      </Button>
                      <Button color="primary" style={{ background: 'blue' }} onClick={() => saveChanges()}>
                        แก้ไขข้อมูล
                      </Button>
                    </AvForm>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default MemberGroup
