import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"

// Charts
import BarChart from "../../components/Common/AllCharts/chartjs/barchart"
import PieChart from "../../components/Common/AllCharts/chartjs/piechart"

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next"
import { getBuyWasteDts, getBuyWasteHds, getReceiveWasteDts, getSaleWasteHds } from 'helpers/backend_helper';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { getCurrentDate, getFirstDayOfTheYear } from 'utils/getDate';
import { getBarChartData } from 'utils/getBarChartData';

const Dashboard = props => {
  const [reducedCo2E, setReducedCo2E] = useState();
  const [generatedOil, setGeneratedOil] = useState();
  const [totalBuyNumber, setTotalBuyNumber] = useState();
  const [totalSaleNumber, setTotalSaleNumber] = useState();
  const [totalKgBuy, setTotalKgBuy] = useState();
  const [totalKgReceive, setTotalKgReceive] = useState();
  const [reducedCo2EByMonth, setReducedCo2EByMonth] = useState(getBarChartData((new Date).getMonth() + 1));
  const [startDate, setStartDate] = useState(getFirstDayOfTheYear());
  const [endDate, setEndDate] = useState(getCurrentDate());

  const getReducedCo2E = async () => {
    try {
      const result = await getBuyWasteDts(`?created_at_gte=${startDate}&created_at_lte=${endDate}`);
      const tempReducedCo2E = result.reduce((acc, curr) => {
        return acc + curr.co2LineTotal ? curr.co2LineTotal : 0
      }, 0)
      const tempTotalKgBuy = result.reduce((acc, curr) => {
        return acc + curr.buyQty ? curr.buyQty : 0
      }, 0)
      setTotalKgBuy(tempTotalKgBuy);
      setReducedCo2E(tempReducedCo2E)
      setReducedCo2EByMonth((prev) => {
        let response = { ...prev }
        result.forEach((data) => {
          const index = Number(new Date(data.created_at).getMonth())
          response.data[index] += data.co2LineTotal ? data.co2LineTotal : 0
        })
        return response
      })

    } catch (err) {
      console.log(err)
      setReducedCo2E(0)
    }
  }

  const getGeneratedOil = async () => {
    try {
      const result = await getReceiveWasteDts(`?wasteMaterial_eq=${26}&created_at_gte=${startDate}&created_at_lte=${endDate}`);
      const generatedOil = result.reduce((acc, curr) => {
        return acc + curr.receiveQty ? curr.receiveQty : 0
      }, 0)
      const tempTotalKgReceive = result.reduce((acc, curr) => {
        return acc + curr.receiveQty ? curr.receiveQty : 0
      }, 0)
      setTotalKgReceive(tempTotalKgReceive);
      setGeneratedOil(generatedOil)
    } catch (err) {
      console.log(err)
      setGeneratedOil(0)
    }
  }

  const getTotalBuyNumber = async () => {
    try {
      const result = await getBuyWasteHds(`?created_at_gte=${startDate}&created_at_lte=${endDate}`);
      const totalBuyNumber = result.reduce((acc, curr) => {
        return acc + curr.buyPriceTotal ? curr.buyPriceTotal : 0
      }, 0)
      setTotalBuyNumber(totalBuyNumber)
    } catch (err) {
      console.log(err)
      setTotalBuyNumber(0)
    }
  }

  const getTotalSaleNumber = async () => {
    try {
      const result = await getSaleWasteHds(`?created_at_gte=${startDate}&created_at_lte=${endDate}`);
      const totalSaleNumber = result.reduce((acc, curr) => {
        return acc + curr.saleTotalPrice ? curr.saleTotalPrice : 0
      }, 0)
      setTotalSaleNumber(totalSaleNumber)
    } catch (err) {
      console.log(err)
      setTotalSaleNumber(0)
    }
  }

  const handleSubmitData = async (event, errors, values) => {

  }

  useEffect(() => {
    getReducedCo2E()
    getGeneratedOil()
    getTotalBuyNumber()
    getTotalSaleNumber()
  }, [startDate, endDate])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>ภาพรวม | วิสาหกิจชุมชนแนวร่วมปฎิวัติขยะ</title>
        </MetaTags>

        <Container fluid>
          <div className="page-title-box">
            <AvForm model={{ "from_date": startDate, "to_date": endDate }} onSubmit={handleSubmitData}>
              <Row className="align-items-center">
                <Col md={6}>
                  <h6 className="page-title">ภาพรวม</h6>
                </Col>
                <Col md={6}>
                  <Row className="align-items-end">
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField name="from_date" label="วันเริ่มต้น" type="date" default onChange={(e) => setStartDate(e.target.value)} />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <AvField name="to_date" label="วันสิ้นสุด" type="date" default onChange={(e) => setEndDate(e.target.value)} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </AvForm>
          </div>
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-0">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      ปริมาณ CO2E ที่ลดได้
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {reducedCo2E}
                    </h4>
                    <p className="text-white-50 text-end">ตัน CO2E</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-dark text-white">
                <CardBody>
                  <div className="mb-0">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      ปริมาณน้ำมันที่ผลิตได้
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {generatedOil}
                    </h4>
                    <p className="text-white-50 text-end">ลิตร</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-warning text-white">
                <CardBody>
                  <div className="mb-0">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      ยอดรับซื้อ
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {totalBuyNumber}
                    </h4>
                    <p className="text-white-50 text-end">บาท</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-info text-white">
                <CardBody>
                  <div className="mb-0">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      ยอดขายออก
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      {totalSaleNumber}
                    </h4>
                    <p className="text-white-50 text-end">บาท</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">ปริมาณ CO2E ที่ลดได้</CardTitle>
                  <BarChart labels={reducedCo2EByMonth.labels} data={reducedCo2EByMonth.data} />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">สัดส่วนประเภทรับเข้า</CardTitle>
                  <PieChart totalKgReceive={totalKgReceive ? totalKgReceive : 0} totalKgBuy={totalKgBuy ? totalKgBuy : 0} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(Dashboard)
