import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"

import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getMembers, getWasteMaterials, postReceiveWasteDts, postReceiveWasteHds } from "helpers/backend_helper";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useInventoryContext } from "helpers/InventoryProvider";
import { getDateString } from "utils/getDate";

const ReceiveWaste = () => {
  const [user, setUser] = useState({})
  const [selectedMember, setSelectedMember] = useState(null)
  const [memberList, setMemberList] = useState([])
  const [wasteMaterialList, setWasteMaterialList] = useState([{
    key: null,
    value: null,
    co2E: 0
  }])
  const [receiveNote, setReceiveNote] = useState("")
  const [items, setItems] = useState([{
    wasteMaterial: null,
    qty: null
  }])
  const { updateData, updateInventory, communityId } = useInventoryContext()

  function handleSelectMember(selectedMember) {
    setSelectedMember(selectedMember)
  }

  const handleSubmitData = async (event, errors, values) => {
    const time = values.date + 'T00:00:00.000Z'
    if (!selectedMember) {
      window.alert('Please select a member')
      return
    }
    items.forEach((item) => {
      if (!item.wasteMaterial) {
        window.alert('Please select a waste material')
        return
      }
      if (!item.qty) {
        window.alert('Please enter a quantity')
        return
      }
    })
    try {
      const response = await postReceiveWasteHds({
        member: selectedMember.value,
        community: communityId,
        status: 'normal',
        receiveNote,
        users_permissions_user: user['id'],
        receiveDate: time,
      })
      const receiveHdId = response.id
      for (const item of items) {
        const wasteId = item.wasteMaterial.value
        await postReceiveWasteDts({
          receiveHd: receiveHdId,
          wasteMaterial: wasteId,
          receiveQty: item.qty,
          co2E: item.wasteMaterial.co2E,
          co2LineTotal: item.qty * item.wasteMaterial.co2E,
          receiveDate: time,
        })
        await updateInventory(wasteId, item.qty)
      }
      await updateData()
      window.alert('บันทึกเรียบร้อย')
      setItems(
        [{
          wasteMaterial: null,
          qty: 0
        }]
      )
      setSelectedMember(null)
    }
    catch (error) {
      console.log(error)
    }
  }

  function handleAddRowNested1() {
    const newItem = {
      wasteMaterial: null,
      qty: null
    }
    setItems((prev) => [...prev, newItem])
  }

  const handleSelectWasteMaterial = (item, idx) => {
    const newItems = [...items]
    newItems[idx].wasteMaterial = item
    setItems(newItems)
  }

  const handleOnChangeQty = (e, idx) => {
    const newItems = items.map((item, sidx) => {
      if (idx !== sidx) return item
      return { ...item, qty: Number(e.target.value) }
    })
    setItems(newItems)
  }

  const deleteItem = (idx) => {
    const newItems = items.filter((item, sidx) => idx !== sidx)
    setItems(newItems)
  }

  const getAllMembers = async () => {
    const response = await getMembers()
    if (response) {
      const optionResponse = response.map(item => {
        return { label: `Name:${item.memberName} (id:${item.id} mobile:${item.mobileNo})`, value: item.id }
      })
      setMemberList(optionResponse)
    }
  }

  const getInitialWasteMaterials = async () => {
    const response = await getWasteMaterials()
    if (response) {
      const optionResponse = response.filter((item) => item.status == 'active').map(item => {
        return { label: `${item.wasteName} (id: ${item.id})`, value: item.id, co2E: item.co2E }
      })
      setWasteMaterialList(optionResponse)
    }
  }

  useEffect(() => {
    getAllMembers()
    getInitialWasteMaterials()
  }, [])

  const getCurrentDate = () => {
    const today = new Date()
    const month = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
    const date = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
    return today.getFullYear() + '-' + month + '-' + date
  }

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem('authUser'))
    if (authUser) {
      setUser({
        id: authUser.user.id,
        username: authUser.user.username,
        email: authUser.user.email,
        displayName: authUser.user.displayName,
        communityId: `${authUser.user.community.communityDesc} (id: ${authUser.user.community.id})`,
        role: authUser.user.role.name,
      })
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>รับบริจาค | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <AvForm model={{ "date": getCurrentDate() }} onSubmit={handleSubmitData}>
            <Breadcrumbs maintitle="เมูนหลัก" title="รับเข้า" breadcrumbItem="รับบริจาค" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">หน้ารับบริจาค</h4>
                    <Row>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label>ชื่อสมาชิก</Label>
                          <Select
                            value={selectedMember}
                            onChange={(e) => {
                              handleSelectMember(e)
                            }}
                            options={memberList}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col lg="2">
                        <div className="mb-3">
                          <AvField name="date" label="วันที่รับบริจาค" type="date" default />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">กรุณาระบุรหัสสินค้า</CardTitle>
                            <Form className="repeater">
                              {items.map((itemData, idx) => (
                                <React.Fragment key={idx}>
                                  <div data-repeater-list="group-a">
                                    <div data-repeater-item className="row">
                                      <div className="mb-4 col-lg-3">
                                        <Label>ชื่อสินค้า</Label>
                                        <Select
                                          value={itemData.wasteMaterial}
                                          onChange={(e) => {
                                            handleSelectWasteMaterial(e, idx)
                                          }}
                                          options={wasteMaterialList}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                      <div className="mb-4 col-lg-3">
                                        <label className="form-label" htmlFor="subject">จำนวน(กิโลกรัม)</label>
                                        <input
                                          type="number"
                                          id="subject"
                                          className="form-control"
                                          value={itemData.qty}
                                          onChange={(e) => handleOnChangeQty(e, idx)} />
                                      </div>
                                      <Col lg={2} className="align-self-center">
                                        <div className="d-grid">
                                          <input
                                            data-repeater-delete
                                            type="button"
                                            className="btn btn-primary"
                                            value="Delete"
                                            onClick={() => deleteItem(idx)}
                                          />
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))}
                              <Button
                                onClick={() => {
                                  handleAddRowNested1()
                                }}
                                color="success"
                                className="btn btn-success mt-3 mt-lg-0"
                              >
                                Add
                              </Button>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="mb-3">
              <Label className="form-label" htmlFor="formmessage">หมายเหตุ :</Label>
              <Input
                type="textarea"
                id="formmessage"
                className="form-control"
                rows="3"
                onChange={(e) => setReceiveNote(e.target.value)}
              />
            </div>
            <Button type="submit" color="primary" >
              บันทึกรายการ
            </Button>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReceiveWaste
