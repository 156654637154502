//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN = "/auth/local"
export const POST_JWT_LOGIN = "/auth/local"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

// WASTE_MATERIALS
export const GET_WASTE_MATERIALS = "/waste-materials"
// WASTE TYPES
export const GET_WASTE_TYPES = "/waste-types"

// Members
export const GET_ALL_MEMBERS = "/members"

// Member Groups
export const GET_ALL_MEMBER_GROUPS = "/member-groups"

// WasteIn
// ReceiveWaste
export const RECEIVE_WASTE_HDS = "/receive-hds"
export const RECEIVE_WASTE_DTS = "/receive-dts"
// BuyWaste
export const BUY_WASTE_HDS = "/buy-hds"
export const BUY_WASTE_DTS = "/buy-dts"

// WasteOut
// SaleWaste
export const SALE_WASTE_HDS = "/sale-hds"
export const SALE_WASTE_DTS = "/sale-dts"
// WithdrawWaste
export const WITHDRAW_WASTE_HDS = "/withdraw-hds"
export const WITHDRAW_WASTE_DTS = "/withdraw-dts"

// COMMUNITY_LOCATIONS
export const COMMUNITY_LOCATIONS = "/community-locations"

// USERS
export const USERS = "/users"

// INVENTORY
export const INVENTORY = "/inventories"