export const getFirstDayOfTheYear = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), 0, 1);
  const year = firstDay.getFullYear();
  const month = firstDay.getMonth() + 1 < 10 ? `0${firstDay.getMonth() + 1}` : firstDay.getMonth() + 1;
  const day = firstDay.getDate() < 10 ? `0${firstDay.getDate()}` : firstDay.getDate();
  return `${year}-${month}-${day}`
}

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  return `${year}-${month}-${day}`
}

export const getDateString = (datetime) => {
  const date = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
  const month = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1;
  const year = datetime.getFullYear();
  return `${year}-${month}-${date}T00:00:00.000Z`
}