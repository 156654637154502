import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
  CardTitle,
  Container,
  Label,
  Table,
} from "reactstrap"
import Select from 'react-select'

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { getMemberGroups, getMembers, postMember } from "helpers/backend_helper";

const Members = () => {
  const [checkState, setCheckState] = useState({ stockOption: 'N' })
  const [memberList, setMemberList] = useState([])
  const [selectedMemberGroup, setSelectedMemberGroup] = useState({})
  const [memberGroupList, setMemberGroupList] = useState([])

  const convertPayload = (payload, type) => {
    if (type === "mainJobIncome") {
      const mainJobObjectMapping = {
        "ต่ำกว่า 5,000 บาท": "LT5K",
        "5,000 - 10,000 บาท": "BT5K_10K",
        "10,000 - 15,000 บาท": "BT10K_15K",
        "มากกว่า 15,000 บาท": "GT15K",
      }
      return mainJobObjectMapping[payload]
    } else if (type === "secondJobIncome") {
      const secondJobObjectMapping = {
        "ต่ำกว่า 5,000 บาท": "LT5K",
        "5,000 - 10,000 บาท": "BT5K_10K",
        "10,000 - 15,000 บาท": "BT10K_15K",
        "มากกว่า 15,000 บาท": "GT15K",
      }
      return secondJobObjectMapping[payload]
    }
  }

  const handleSubmit = async (event, errors, values) => {
    const payload = {
      ...values,
      memberName: values["fullName"],
      familyMale: Number(values["familyMale"]),
      familyFemale: Number(values["familyFemale"]),
      familyChildren: Number(values["familyChildren"]),
      zipCode: Number(values["zipCode"]),
      mobileNo: Number(values["mobileNo"]),
      mainJobIncome: convertPayload(values["mainJobIncome"], "mainJobIncome"),
      secondJobIncome: convertPayload(values["secondJobIncome"], "secondJobIncome"),
      memberGroup: selectedMemberGroup.value,
    }
    delete payload.fullName;
    await postMember(payload)
  }

  const model = {
    memberPrefix: "นาย",
    mainJobIncome: "ต่ำกว่า 5,000 บาท",
    secondJobIncome: "ต่ำกว่า 5,000 บาท",
    stockOption: "N",
    familyMember: 2,
    familyMale: 0,
    familyFemale: 0,
    familyChildren: 0,
    managedGarbage: "N",
    selledGarbage: "N",
    memberType: "ordinary"
  };

  const handleChangeStockOption = (value) => {
    setCheckState({ ...checkState, stockOption: value.target.value })
  }

  const getAllMembers = async () => {
    const response = await getMembers()
    setMemberList(response)
  }

  useEffect(() => {
    getAllMembers()
  }, [])

  const getAllMemberGroups = async () => {
    const response = await getMemberGroups()
    const memberGroupList = response.map(member => ({ label: `${member.memberGroupName} (id: ${member.id})`, value: member.id }))
    setMemberGroupList(memberGroupList)
  }

  const handleSelectMemberGroup = (e) => {
    setSelectedMemberGroup(e)
  }

  useEffect(() => {
    getAllMemberGroups()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>สมาชิก | วิสาหกิจชุมชนแนวร่วมปฏิวัติขยะ</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs maintitle="เมนู" title="สมาชิก" breadcrumbItem="สมาชิก" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">ใบสมัครสมาชิกวิสาหกิจชุมชน แนวร่วมปฏิวัติขยะสุพรรณบุรี</CardTitle>
                  <p className="card-title-desc">
                  </p>
                  <AvForm className="needs-validation" onSubmit={handleSubmit} model={model}>
                    <Row>
                      <Col lg="2">
                        <div className="mb-3">
                          <Label>คำนำหน้า</Label>
                          <AvField type="select" name="memberPrefix" default validate={{ required: { value: true } }}>
                            <option>นาย</option>
                            <option>นาง</option>
                            <option>นางสาว</option>
                            <option>บริษัท</option>
                            <option>สมาคม</option>
                            <option>ราชการ</option>
                            <option>อื่นๆ</option>
                          </AvField>
                        </div>
                      </Col>
                      <Col md="5">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">
                            ชื่อ
                          </Label>
                          <AvField
                            name="fullName"
                            placeholder="ชื่อ-นามสกุล"
                            type="text"
                            errorMessage="Enter Full Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">
                            เลขบัตรประจำตัวประชาชน (ไม่มี -)
                          </Label>
                          <AvField
                            name="citizenId"
                            placeholder="เลขบัตรประจำตัวประชาชน"
                            type="number"
                            errorMessage="Enter ID number"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">
                            วันเดือนปีเกิด
                          </Label>
                          <AvField
                            name="birthdate"
                            placeholder="วัน/เดือน/ปี"
                            type="date"
                            errorMessage="Enter Birth Date"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom02"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">กลุ่ม</Label>
                          <Select
                            value={selectedMemberGroup}
                            onChange={(e) => {
                              handleSelectMemberGroup(e)
                            }}
                            options={memberGroupList}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">ที่อยู่</Label>
                          <AvField
                            name="address"
                            placeholder="ที่อยู่"
                            type="text"
                            errorMessage=" Please provide a valid address."
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">เบอร์โทรศัพท์</Label>
                          <AvField
                            name="mobileNo"
                            placeholder="เบอร์โทรศัพท์"
                            type="number"
                            errorMessage=" Please provide a valid mobile no."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">รหัสไปรษณีย์</Label>
                          <AvField
                            name="zipCode"
                            placeholder="Zip Code"
                            type="text"
                            errorMessage=" Please provide a valid zip."
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <CardTitle className="h4">อาชีพและรายได้</CardTitle>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">อาชีพหลัก</Label>
                          <AvField
                            name="mainJob"
                            placeholder="อาชีพหลัก"
                            type="text"
                            errorMessage=" Please provide a valid zip."
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label>รายได้เฉลี่ย เดือนล่ะ</Label>
                          <AvField type="select" name="mainJobIncome">
                            <option>ต่ำกว่า 5,000 บาท</option>
                            <option>5,000 - 10,000 บาท</option>
                            <option>10,000 - 15,000 บาท</option>
                            <option>มากกว่า 15,000 บาท</option>
                          </AvField>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">อาชีพรอง</Label>
                          <AvField
                            name="secondJob"
                            placeholder="อาชีพรอง"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label>รายได้เฉลี่ย เดือนล่ะ</Label>
                          <AvField type="select" name="secondJobIncome" >
                            <option>ต่ำกว่า 5,000 บาท</option>
                            <option>5,000 - 10,000 บาท</option>
                            <option>10,000 - 15,000 บาท</option>
                            <option>มากกว่า 15,000 บาท</option>
                          </AvField>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label>จำนวนสมาชิกในครัวเรือน (รวมตัวเอง)</Label>
                          <AvField
                            name="familyMember"
                            placeholder="จำนวนสมาชิกในครัวเรือน (รวมตัวเอง)"
                            type="number"
                            className="form-control"
                            id="familyMember"
                            errorMessage="This field is required."
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label>ชาย</Label>
                          <AvField
                            name="familyMale"
                            placeholder="จำนวนสมาชิกเพศชาย"
                            type="number"
                            className="form-control"
                            id="familyMember"
                            errorMessage="This field is required."
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label>หญิง</Label>
                          <AvField
                            name="familyFemale"
                            placeholder="จำนวนสมาชิกเพศหญิง"
                            type="number"
                            className="form-control"
                            id="familyMember"
                            errorMessage="This field is required."
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label>เด็ก</Label>
                          <AvField
                            name="familyChildren"
                            placeholder="จำนวนสมาชิกเด็ก"
                            type="number"
                            className="form-control"
                            id="familyMember"
                            errorMessage="This field is required."
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Row lg="12">
                        <Col lg="3">
                          <div className="mb-3">
                            <Label>ปัจจุบันมีการคัดแยกขยะหรือไม่</Label>
                            <div className="form-check">
                              <AvRadioGroup inline name="managedGarbage" errorMessage="This field is required.">
                                <AvRadio customInput label="เคย" value="Y" />
                                <AvRadio customInput label="ไม่เคย" value="N" />
                              </AvRadioGroup>
                            </div>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label>เคยขายที่คัดแยกหรือไม่</Label>
                            <div className="form-check">
                              <AvRadioGroup inline name="selledGarbage">
                                <AvRadio customInput label="เคย" value="Y" />
                                <AvRadio customInput label="ไม่เคย" value="N" />
                              </AvRadioGroup>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                    <Row>
                      <CardTitle className="h4">สมาชิกประเภท</CardTitle>
                      <Col lg="3">
                        <div className="mb-3">
                          <div className="form-check">
                            <AvRadioGroup inline name="memberType">
                              <AvRadio customInput label="สามัญ" value={"ordinary"} />
                              <AvRadio customInput label="วิสามัญ" value={"extraordinary"} />
                              <AvRadio customInput label="กิตติมศักดิ์" value={"honorary"} />
                            </AvRadioGroup>
                          </div>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <CardTitle className="h4">หุ้นวิสาหกิจชุมชน</CardTitle>
                      <Col lg="3">
                        <div className="mb-3">
                          <div className="form-check">
                            <AvRadioGroup inline name="stockOption" validate={{ required: { value: true } }} onChange={handleChangeStockOption}>
                              <AvRadio customInput label="ไม่มีความประสงค์ขอถือหุ้น" value={"N"} />
                              <AvRadio customInput label="มีความประสงค์ขอถือหุ้น" value={"Y"} />
                            </AvRadioGroup>
                          </div>
                        </div>
                      </Col>
                      {checkState.stockOption === "Y" && (
                        <>
                          <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">จำนวนหุ้น</Label>
                              <AvField
                                name="stockQty"
                                placeholder="จำนวนหุ้น"
                                type="number"
                                errorMessage=" Please provide a valid zip."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom05"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">รวมเป็นจำนวนเงิน</Label>
                              <AvField
                                name="stockPrice"
                                placeholder="รวมจำนวนเงิน"
                                type="number"
                                errorMessage=" Please provide a valid zip."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom05"
                              />
                            </FormGroup>
                          </Col>
                        </>)
                      }
                    </Row>
                    <Row>
                      <CardTitle className="h4">ผู้รับผลประโยชน์</CardTitle>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">ชื่อ-นามสกุล</Label>
                          <AvField
                            name="benefit1"
                            placeholder="ชื่อ-นามสกุล"
                            type="text"
                            errorMessage=" Please provide a valid name."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <div className="mb-3">
                          <Label>ความสัมพันธ์</Label>
                          <AvField type="select" name="relation1" >
                            <option>เลือกความสัมพันธ์</option>
                            <option>สามี</option>
                            <option>ภรรยา</option>
                            <option>บุตร</option>
                            <option>บิดา</option>
                            <option>มารดา</option>
                            <option>พี่</option>
                            <option>น้อง</option>
                            <option>ญาติ</option>
                            <option>อื่น ๆ</option>
                          </AvField>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <AvField
                            name="benefit2"
                            placeholder="ชื่อ-นามสกุล"
                            type="text"
                            errorMessage=" Please provide a valid name."
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom05"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <div className="mb-3">
                          <AvField type="select" name="relation2" >
                            <option>เลือกความสัมพันธ์</option>
                            <option>สามี</option>
                            <option>ภรรยา</option>
                            <option>บุตร</option>
                            <option>บิดา</option>
                            <option>มารดา</option>
                            <option>พี่</option>
                            <option>น้อง</option>
                            <option>ญาติ</option>
                            <option>อื่น ๆ</option>
                          </AvField>
                        </div>
                      </Col>
                    </Row>

                    <Button color="primary" type="submit">
                      บันทึกข้อมูล
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="h4">รายชื่อสมาชิก</CardTitle>
                  <p className="card-title-desc">
                  </p>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>คำนำหน้าชื่อ</th>
                          <th>ชื่อ-นามสกุล</th>
                          <th>อาชีพหลัก</th>
                          <th>ที่อยู่</th>
                          <th>วันที่สมัคร</th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberList && memberList.map((member, index) => {
                          return (
                            <tr key={member.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{member.memberPrefix}</td>
                              <td>{member.memberName}</td>
                              <td>{member.mainJob}</td>
                              <td>{member.address}</td>
                              <td>{member.created_at}</td>
                            </tr>
                          )
                        })}
                        {/* <tr>
                          <th>#</th>
                          <th>ประเภทเอกสาร</th>
                          <th>เลขที่เอกสาร</th>
                          <th>รหัสสินค้า</th>
                          <th>จำนวนเบิก(กก.)</th>
                          <th>ผู้เบิก</th>
                          <th>สถานะเอกสาร</th>
                        </tr> */}
                        {/* <tr>
                          <th scope="row">1</th>
                          <td>รับบริจาค</td>
                          <td>001</td>
                          <td>001</td>
                          <td>10</td>
                          <td>ชานนท์</td>
                          <td>ปกติ</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>รับซื้อ</td>
                          <td>002</td>
                          <td>001</td>
                          <td>4</td>
                          <td>ชวิศ</td>
                          <td>ปกติ</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>รับบริจาค</td>
                          <td>002</td>
                          <td>001</td>
                          <td>50</td>
                          <td>ชานนท์</td>
                          <td>ปกติ</td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment >
  )
}

export default Members
