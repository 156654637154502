export const getBarChartData = (month) => {
  const defaultMonth = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
  let response = {
    labels: [],
    data: []
  }
  const getArrayOfMonth = (number) => {
    const array = [];
    for (let i = 0; i < number; i++) {
      response['labels'].push(defaultMonth[i]);
      response['data'].push(0);
    }
    return array;
  }

  getArrayOfMonth(month)

  return response
}